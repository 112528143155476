import { Box, Button, Flex, Text } from "components/primitives";
import Img from 'components/primitives/Img'
import { useRouter } from "next/router";
import useMarketplaceChain from "hooks/useMarketplaceChain";

import { paths } from '@reservoir0x/reservoir-sdk'
import { ChainContext } from 'context/ChainContextProvider'
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next'
import {
    ComponentPropsWithoutRef,
    useContext,
    useEffect,
    useState,
} from 'react'
import supportedChains from 'utils/chains'
import {
    useTrendingCollections,
    useTrendingMints,
} from '@reservoir0x/reservoir-kit-ui'
import {
    CollectionsSortingOption,
} from 'components/common/CollectionsTimeDropdown'
import {
    MintsSortingOption,
} from 'components/common/MintsPeriodDropdown'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import fetcher from 'utils/fetcher'

type TabValue = 'collections' | 'mints'

type Props = InferGetServerSidePropsType<typeof getServerSideProps>
const NFTBanner: NextPage = () => {
    const router = useRouter()
    const { routePrefix } = useMarketplaceChain()
    // not sure if there is a better way to fix this
    const { theme: nextTheme } = useTheme()
    const [theme, setTheme] = useState<string | null>(null)
    useEffect(() => {
        if (nextTheme) {
            setTheme(nextTheme)
        }
    }, [nextTheme])

    const isSSR = typeof window === 'undefined'
    const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })

    const [tab, setTab] = useState<TabValue>('collections')
    const [sortByTime, setSortByTime] = useState<CollectionsSortingOption>('24h')

    const [sortByPeriod, setSortByPeriod] = useState<MintsSortingOption>('24h')

    let mintsQuery: Parameters<typeof useTrendingMints>['0'] = {
        limit: 20,
        period: sortByPeriod,
        type: 'any',
    }

    const { chain, switchCurrentChain } = useContext(ChainContext)

    useEffect(() => {
        if (router.query.chain) {
            let chainIndex: number | undefined
            for (let i = 0; i < supportedChains.length; i++) {
                if (supportedChains[i].routePrefix == router.query.chain) {
                    chainIndex = supportedChains[i].id
                }
            }
            if (chainIndex !== -1 && chainIndex) {
                switchCurrentChain(chainIndex)
            }
        }
    }, [router.query])

    const {
        data: trendingCollections,
        isValidating: isTrendingCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'volume',
            period: sortByTime,
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const {
        data: featuredCollections,
        isValidating: isFeaturedCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'sales',
            period: '24h',
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const { data: trendingMints, isValidating: isTrendingMintsValidating } =
        useTrendingMints({ ...mintsQuery }, chain.id, {
            fallbackData: "",
            keepPreviousData: true,
        })
    let volumeKey: ComponentPropsWithoutRef<
        typeof CollectionRankingsTable
    >['volumeKey'] = '1day'

    switch (sortByTime) {
        case '30d':
            volumeKey = '30day'
            break
        case '7d':
            volumeKey = '7day'
            break
        case '24h':
            volumeKey = '1day'
            break
    }
    console.log("featuredCollections", featuredCollections)
    return (
        <>

            <Flex
                justify="between"
                align="center"
                direction="column"
                css={{
                    gap: 24,
                    mb: '$4',
                    "@md": {
                        flexDirection: 'row',
                        alignItems: 'center',
                    },
                }}
            >
                <Box
                    css={{
                        height: '100%',
                        width: '100%',
                        gap: "$4",
                        "@md": {
                            width: '100%',
                        }
                    }}>

                    <Text style="h2" as="h2">The premier Arbitrum NFT platform,</Text>
                    <Text style="h4" as="h4">Accessible to everyone and created by anyone.</Text>
                    <Button css={
                        {
                            marginTop: "$6",
                            // backgroundColor: "#FFA201",
                        }

                    } onClick={
                        () => {
                            router.push(`/${routePrefix}/explore`)
                        }
                    }>Explore</Button>
                </Box>


                <Box
                    css={{
                        height: '100%',
                        width: '100%',
                        borderRadius: "20px",
                        "@md": {
                            width: '650x;',

                        }

                    }} onClick={() => {
                        router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                    }}>
                    <Img css={{

                        width: "800px",
                        // height: "500px"
                    }}
                        src={"/index/UshanftBanner_1.svg"}
                        alt="Ushanft" width={500} height={500} />
                </Box>
            </Flex >
            <Box css={
                {
                    height: "100%",
                    width: "100%",
                    marginTop: "100px"
                }
            }>
                <Text css={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#000000",

                }} style="h3" as="h3">Category</Text>

            </Box>
            <Flex justify="between" align="center" direction="column" wrap="wrap" css={{
                gap: 24,
                mb: '$4',
                marginTop: "100px",
                gridTemplateColumns:
                    'repeat(auto-fill, minmax(200px, 1fr))',
                "@md": {
                    flexDirection: 'row',
                    alignItems: 'center',
                    gridTemplateColumns:
                        'repeat(auto-fill, minmax(240px, 1fr))',
                },

            }}>
                <Box css={{
                    height: "100%",
                    width: "100%",

                    "@md": {
                        width: "30%",
                    }
                }} onClick={() => {
                    router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                }}>
                    <Img css={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        width: "100%",
                        cursor: "pointer",
                        "@md": {
                            width: "100%",
                            cursor: "pointer",
                        }
                    }}
                        src={"/index/art-nft_1.svg"}
                        alt="art" width={500} height={500} />
                    <Box css={{
                        width: "100%",
                        background: "#3E3E3E",
                        height: "50px",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px"
                    }}>
                        <Text css={
                            {
                                textAlign: "center",
                                color: "#FFA201",
                                fontSize: "20px",
                                fontWeight: "bold",
                                lineHeight: "50px",
                            }
                        } style="h4" as="h4">Art</Text>
                    </Box>
                </Box>
                <Box css={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "20px",
                    backgroundColor: "#3E3E3E",
                    "@md": {
                        width: "30%",
                    }
                }} onClick={() => {
                    router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                }}>
                    <Img css={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        width: "100%",
                        cursor: "pointer",
                        "@md": {
                            width: "100%",
                            cursor: "pointer",
                        }
                    }}
                        src={"/index/community_1.svg"}
                        alt="Community" width={500} height={500} />
                    <Box css={{

                        background: "#3E3E3E",
                        height: "50px",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px"
                    }}>
                        <Text css={
                            {
                                textAlign: "center",
                                color: "#FFA201",
                                fontSize: "20px",
                                fontWeight: "bold",
                                lineHeight: "50px",
                            }
                        } style="h4" as="h4">Community</Text>
                    </Box>
                </Box>
                <Box css={{
                    height: "100%",
                    width: "100%",
                    "@md": {
                        width: "30%",
                    }
                }} onClick={() => {
                    router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                }}>
                    <Img css={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        width: "100%",
                        // height: "275px",
                        cursor: "pointer",
                        "@md": {
                            width: "100%",
                            // height: "400px",
                            cursor: "pointer",
                        }
                    }}
                        src={"/index/game_1.svg"}
                        alt="game" width={500} height={500} />
                    <Box css={{

                        background: "#3E3E3E",
                        height: "50px",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px"
                    }}>
                        <Text css={
                            {
                                textAlign: "center",
                                color: "#FFA201",
                                fontSize: "20px",
                                fontWeight: "bold",
                                lineHeight: "50px",
                            }
                        } style="h4" as="h4">Gaming</Text>
                    </Box>
                </Box>
                <Box css={{
                    height: "100%",
                    width: "100%",
                    "@md": {
                        width: "30%",
                    }
                }} onClick={() => {
                    router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                }}>
                    <Img css={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        width: "100%",
                        // height: "275px",
                        cursor: "pointer",
                        "@md": {
                            width: "100%",
                            // height: "400px",
                            cursor: "pointer",
                        }
                    }}
                        src={"/index/membership_1.svg"}
                        alt="membership" width={500} height={500} />
                    <Box css={{

                        background: "#3E3E3E",
                        height: "50px",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px"
                    }}>
                        <Text css={
                            {
                                textAlign: "center",
                                color: "#FFA201",
                                fontSize: "20px",
                                fontWeight: "bold",
                                lineHeight: "50px",
                            }
                        } style="h4" as="h4">Memberships</Text>
                    </Box>
                </Box>
                <Box css={{
                    height: "100%",
                    width: "100%",
                    "@md": {
                        width: "30%",
                    }
                }} onClick={() => {
                    router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                }}>
                    <Img css={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        width: "100%",
                        // height: "275px",
                        cursor: "pointer",
                        "@md": {
                            width: "100%",
                            // height: "400px",
                            cursor: "pointer",
                        }
                    }}
                        src={"/index/music_1.svg"}
                        alt="music" width={500} height={500} />
                    <Box css={{

                        background: "#3E3E3E",
                        height: "50px",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px"
                    }}>
                        <Text css={
                            {
                                textAlign: "center",
                                color: "#FFA201",
                                fontSize: "20px",
                                fontWeight: "bold",
                                lineHeight: "50px",
                            }
                        } style="h4" as="h4">Music</Text>
                    </Box>
                </Box>
                <Box css={{
                    height: "100%",
                    width: "100%",
                    "@md": {
                        width: "30%",
                    }
                }} onClick={() => {
                    router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                }}>
                    <Img css={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        width: "100%",
                        // height: "275px",
                        cursor: "pointer",
                        "@md": {
                            width: "100%",
                            // height: "400px",
                            cursor: "pointer",
                        }
                    }}
                        src={"/index/photo_1.svg"}
                        alt="Photography" width={500} height={500} />
                    <Box css={{

                        background: "#3E3E3E",
                        height: "50px",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px"
                    }}>
                        <Text css={
                            {
                                textAlign: "center",
                                color: "#FFA201",
                                fontSize: "20px",
                                fontWeight: "bold",
                                lineHeight: "50px",
                            }
                        } style="h4" as="h4">Photography</Text>
                    </Box>
                </Box>
            </Flex >

            <Flex direction="column" css={{
                gap: 24,

                "@md": {
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: "506px",
                },
            }}>
                <Box css={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    "@md": {
                        width: "30%",
                        justifyContent: "center",
                    }
                }}>
                    <Flex align="center" direction="column" >
                        <Text style='h4' as='h4' css={{
                            fontWeight: "400",
                            fontSize: "40px"
                        }}>
                            New Drops
                        </Text>
                        <p>Discover the latest treasures in the NFT universe with our New Drops section. Get early access to innovative digital art, collectibles, and unique virtual assets as soon as they debut. Claim your piece of the future now!</p>
                        <Button onClick={() => {
                            router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                        }} css={{
                            marginTop: "$4",
                        }}>SEE ALL DROPS </Button>
                    </Flex>
                </Box>
                <Box css={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "@md": {
                        width: "70%",

                    }

                }}>
                    <Flex justify="between" align='center' direction="column" css={{
                        "@md": {

                            width: "100%",
                            flexDirection: "row"
                        }
                    }}>
                        <Box css={{
                            height: "100%",
                            width: "100%",
                            "@md": {
                                width: "30%",
                            }
                        }} onClick={() => {
                            router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                        }}>
                            <Img css={{
                                borderRadius: "20px"
                            }}
                                src={"/index/NFT_1.svg"}
                                alt="INCARNA" width={500} height={500} />
                            <Box css={{
                                width: "100%",
                                height: "50px",
                                textAlign: "center",
                                lineHeight: "50px",
                                fontWeight: "400",
                                fontSize: "18px"
                            }}>
                                INCARNA
                            </Box>
                        </Box>
                        <Box css={{
                            height: "100%",
                            width: "100%",
                            "@md": {
                                width: "30%",
                            }
                        }} onClick={() => {
                            router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                        }}>
                            <Img css={{
                                borderRadius: "20px"
                            }}
                                src={"/index/NFT_2.svg"}
                                alt="INCARNA" width={500} height={500} />
                            <Box css={{
                                width: "100%",
                                height: "50px",
                                textAlign: "center",
                                lineHeight: "50px",
                                fontWeight: "400",
                                fontSize: "18px"
                            }}>
                                INCARNA
                            </Box>
                        </Box>
                        <Box css={{
                            height: "100%",
                            width: "100%",
                            "@md": {
                                width: "30%",
                            }
                        }} onClick={() => {
                            router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                        }}>
                            <Img css={{
                                borderRadius: "20px"
                            }}
                                src={"/index/NFT_3.svg"}
                                alt="SuperRare" width={500} height={500} />
                            <Box css={{
                                width: "100%",
                                height: "50px",
                                textAlign: "center",
                                lineHeight: "50px",
                                fontWeight: "400",
                                fontSize: "18px"
                            }}>
                                SuperRare
                            </Box>
                        </Box>
                    </Flex>
                </Box>
            </Flex >


            {/* tow */}
            <Flex direction="column" css={{
                gap: 24,

                "@md": {
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: "506px",
                },
            }}>
                <Box css={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "@md": {
                        width: "70%",
                        direction: "row"

                    }

                }}>
                    <Flex justify="between" align='center' direction="column" css={{
                        "@md": {
                            width: "100%",
                            flexDirection: "row"

                        }

                    }}>
                        <Box css={{
                            height: "100%",
                            width: "100%",
                            "@md": {
                                width: "30%",
                            }
                        }} onClick={() => {
                            router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                        }}>
                            <Img css={{
                                borderRadius: "20px"
                            }}
                                src={"/index/NFT_4.svg"}
                                alt="Zeeverse" width={500} height={500} />
                            <Box css={{
                                width: "100%",
                                height: "50px",
                                textAlign: "center",
                                lineHeight: "50px",
                                fontWeight: "400",
                                fontSize: "18px"
                            }}>
                                Zeeverse
                            </Box>
                        </Box>
                        <Box css={{
                            height: "100%",
                            width: "100%",
                            "@md": {
                                width: "30%",
                            }
                        }} onClick={() => {
                            router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                        }}>
                            <Img css={{
                                borderRadius: "20px"
                            }}
                                src={"/index/NFT_5.svg"}
                                alt="SuperRare" width={500} height={500} />
                            <Box css={{
                                width: "100%",
                                height: "50px",
                                textAlign: "center",
                                lineHeight: "50px",
                                fontWeight: "400",
                                fontSize: "18px"
                            }}>
                                SuperRare
                            </Box>
                        </Box>
                        <Box css={{
                            height: "100%",
                            width: "100%",
                            "@md": {
                                width: "30%",
                            }
                        }} onClick={() => {
                            router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                        }}>
                            <Img css={{
                                borderRadius: "20px"
                            }}
                                src={"/index/NFT_6.svg"}
                                alt="Zeeverse" width={500} height={500} />
                            <Box css={{
                                width: "100%",
                                height: "50px",
                                textAlign: "center",
                                lineHeight: "50px",
                                fontWeight: "400",
                                fontSize: "18px"
                            }}>
                                Zeeverse
                            </Box>
                        </Box>
                    </Flex>
                </Box >


                <Box css={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    "@md": {
                        width: "30%",
                        justifyContent: "center",
                    }
                }}>
                    <Flex align="center" direction="column" >
                        <Text style='h4' as='h4' css={{
                            fontWeight: "400",
                            fontSize: "40px"
                        }}>
                            Hot Collections
                        </Text>
                        <p>Immerse yourself in the Hot Collections and explore trending NFTs that are captivating the community. From viral digital art to highly sought-after collectible series, find out what's making headlines and enhance your portfolio with these prized items now.</p>
                        <Button onClick={() => {
                            router.push(`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`)
                        }} css={{
                            marginTop: "$4",
                        }}>HOT</Button>
                    </Flex>
                </Box>
            </Flex >
        </>
    )
}


type TrendingCollectionsSchema =
    paths['/collections/trending/v1']['get']['responses']['200']['schema']
type TrendingMintsSchema =
    paths['/collections/trending-mints/v1']['get']['responses']['200']['schema']

export const getServerSideProps: GetServerSideProps<{
    ssr: {
        trendingMints: TrendingMintsSchema
        trendingCollections: TrendingCollectionsSchema
        featuredCollections: TrendingCollectionsSchema
    }
}> = async ({ params, res }) => {
    const chainPrefix = params?.chain || ''
    const reservoirBaseUrl = process.env.NEXT_PUBLIC_HOST_URL
    // init BaseApi
    // supportedChains.find((chain) => chain.routePrefix === chainPrefix) ||
    // DefaultChain
    // console.log("请求的路劲",reservoirBaseUrl)
    const headers: RequestInit = {
        headers: {
            'x-api-key': process.env.RESERVOIR_API_KEY || '',
        },
    }
    let trendingCollectionsQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
    {
        period: '24h',
        limit: 20,
        sortBy: 'volume',
    }

    const trendingCollectionsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending/v1`,
        trendingCollectionsQuery,
        headers
    )
    let featuredCollectionQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
    {
        period: '24h',
        limit: 20,
        sortBy: 'sales',
    }

    const featuredCollectionsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending/v1`,
        featuredCollectionQuery,
        headers
    )

    let trendingMintsQuery: paths['/collections/trending-mints/v1']['get']['parameters']['query'] =
    {
        period: '24h',
        limit: 20,
        type: 'any',
    }

    const trendingMintsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending-mints/v1`,
        trendingMintsQuery,
        headers
    )

    const promises = await Promise.allSettled([
        trendingCollectionsPromise,
        featuredCollectionsPromise,
        trendingMintsPromise,
    ]).catch((e) => {
        console.error(e)
    })

    const trendingCollections: Props['ssr']['trendingCollections'] =
        promises?.[0].status === 'fulfilled' && promises[0].value.data
            ? (promises[0].value.data as Props['ssr']['trendingCollections'])
            : {}
    const featuredCollections: Props['ssr']['featuredCollections'] =
        promises?.[1].status === 'fulfilled' && promises[1].value.data
            ? (promises[1].value.data as Props['ssr']['featuredCollections'])
            : {}

    const trendingMints: Props['ssr']['trendingMints'] =
        promises?.[1].status === 'fulfilled' && promises[1].value.data
            ? (promises[1].value.data as Props['ssr']['trendingMints'])
            : {}

    res.setHeader(
        'Cache-Control',
        'public, s-maxage=120, stale-while-revalidate=180'
    )
    return {
        props: { ssr: { trendingCollections, trendingMints, featuredCollections } },
    }
}

export default NFTBanner